<div class="container">
    <div class="upper-banner">
        <div class="overlay">
        </div>
    </div>
    <div class="banner-title">
        <div class="col-8">
            <div class="d-flex justify-content-left flex-wrap mt-4">
                <div>
                    <h2 class="my-5">{{ 'ABOUTUS' | translate}}</h2> 
                </div>
                <div>
                    <p translate>ABOUTUS-DESC</p>
                </div>
                <div>
                    <a class="btn banner-button" href="byte-shop" target="_blank">{{ 'ABOUTUS-VIEW-PRODUCTS' | translate }}</a>
                </div>
            </div>
        </div>
        
        <!-- <a class="btn banner-button" href="https://www.amazingwaterinc.com/shop/p/amazingair" target="_blank">{{ 'HLEARNM' | translate }}</a> -->
    </div>

    <div class="d-flex justify-content-center vision flex-wrap">
        <div class="vision-card d-flex justify-content-left flex-column gap-3">
            <div class="vision-image">
                <img src="./../../../../assets/images/files/about/heartIcon0928.png" class="vision-image" lt="A beautiful landscape" width="30%">
            </div>
            <div class="vision-card-title" translate>VISION</div>
            <div class="vision-card-description" translate>ABOUTUS-VISION-DESC</div>
        </div>
        
       
            <div class="vision-card justify-content-left flex-column">
                <div class="vision-image">
                    <img src="./../../../../assets/images/files/about/missionIcon0928.png" alt="A beautiful landscape" width="30%">
                </div>
                <div class="vision-card-title" translate>VISION</div>
                <div class="vision-card-description" translate>ABOUTUS-MISSION-DESC</div>
                <div><a class="btn vision-button" href="byte-shop" target="_blank">{{ 'READMORE' | translate }}&nbsp;<i class="bi bi-arrow-right"></i></a></div>
            </div>
            
        
    </div>

    <!--<div class="water-mission">
        <div class="item-left">
            <img style="object-fit:cover; width: 100%;height: 100%;" src="./../../../../assets/images/files/about/about_pic17.jpg" alt="">
        </div>
        <div class="item-right">
            <div class="card-block-1">
                <h1 class="card-title" translate>MISSION</h1>
                <div class="separator mb-3">
                </div>
                <p class="card-text mb-5" translate>ABOUTMISSION</p>
               

            </div>
        </div>
    </div> -->

    <div class="we-care">
        <div class="item-left">
            <div class="card-block-1">
                <h1 class="card-title" translate>WECARE</h1>
                <div class="separator mb-3">
                </div>
                <p class="card-text mb-5" translate>WECAREDESC</p>
                <a class="btn card-link-1" (click)="openPage('products')"  translate>WECAREBTN &nbsp;<i class="bi bi-arrow-right"></i></a>
            </div>
            
        </div>
        <div class="item-right">
            <img style="object-fit:cover; width: 100%;height: 100%;" src="./../../../../assets/images/files/about/about_pic5.jpg" alt="">
        </div>
    </div>

    <div class="hydrogen-energy-tablet">
            <div class="hydrogen-block d-flex justify-content-left flex-column">
                <div class="hydrogen-title" translate>ABOUTUS-HYDROGEN-TITLE</div>
                <div class = "hydrogen-separator mt-3 mb-4"></div>
                <div class="vision-card-description" translate>ABOUTUS-HYDROGEN-DESC</div>
                <div><a class="btn hydrogen-button" (click)="openPage('hydrogen-energy')"  translate>ABOUTUS-MORE-INFO&nbsp;<i class="bi bi-arrow-right"></i></a></div>
            </div>
    </div>

    <div class="we-care">
        <div class="item-left">
            <div class="card-block-1">
                <h1 class="card-title" translate>ABOUTUS-C60-TITLE</h1>
                <div class="separator mb-3">
                </div>
                <p class="card-text mb-5" translate>ABOUTUS-C60-DESC</p>
                <a class="btn card-link-1" (click)="openPage('carbon60')"  translate>WECAREBTN &nbsp;<i class="bi bi-arrow-right"></i></a>
            </div>
            
        </div>
        <div class="item-right">
            <img style="object-fit:cover; width: 100%;height: 100%;" src="./../../../../assets/images/files/about/c60img0928.png" alt="">
        </div>
    </div>

    <div class="achieved2">
        <div class="d-flex justify-content-end">
            <div class="achieved2-content">
                <div class="achieved2-title" translate>ABOUTUS-ACHIEVE-TITLE</div>
                <div class="achieved2-separator mt-3 mb-4"></div>
                <div class="achieved2-text">
                    <ul>
                        <li class="mb-2" translate>ABOUTUS-ACHIEVE-DESC-1</li>
                        <li class="mb-2" translate>ABOUTUS-ACHIEVE-DESC-2</li>
                        <li class="mb-2" translate>ABOUTUS-ACHIEVE-DESC-3</li>
                        <li class="mb-2" translate>ABOUTUS-ACHIEVE-DESC-4</li>
                    </ul>

                </div>

            </div>
            
        </div>

    </div>

    <!--<div class="byte-air">
       
        <div class="byte-air-body">
            <h1 class="byte-air-head" translate>
                AAP
            </h1>
            <div class="d-flex">
                <div class="separator mt-3 mb-3">
        
                </div>
            </div>
            <div class="more-body mb-3">
                <p class="" translate>AAPDESC
                </p>
            </div>
            <a class="btn vision-button" (click)="openPage('air-purifier')" target="_blank">{{ 'AAPBTN' | translate }}</a>
        </div>  
    </div> 
    <div class="washer">
        <div class="card p-5">
          <div class="row" style="margin: 0;">
            <div class="col-sm-5 mb-5 mt-5">
              <div class="card-block-1">
                <h1 class="card-title" translate>FVW</h1>
                <div class="separator mb-3">
                </div>
                <p class="card-text mb-5" translate>FVWDESC</p>
                <a class="btn card-link p-3" (click)="openPage('washer')"  translate>FVWBTN</a>
              </div>
            </div>
            <div class="img-sec col-sm-7" style="padding-left: 0;padding-right: 0; background-color: gray;">
              <div class="card-block-2 border-0">
                <img class="center-image" width="40%" src="./../../../../assets/images/files/about/about_pic8.png" alt="">
              </div>
            </div>
          </div>
          
            
        </div>
    </div> -->
    <!--
     <div class="achieved">
        <div class="item-left">
            <img style="object-fit:cover;width: 100%;height: auto;" src="./../../../../assets/images/files/about/about_pic10.jpg" alt="">
        </div>
        <div class="item-right">
            <div class="card-block-1">
                <h1 class="card-title" translate>ACHIEVED</h1>
                <div class="separator mb-3">
                </div>
                <ul>
                    <li translate>
                        ACHIEVEDDESC1
                    </li>
                    <li translate>
                        ACHIEVEDDESC2
                    </li>
                    <li translate>
                        ACHIEVEDDESC3

                    </li>
                    <li translate>
                        ACHIEVEDDESC4
                    </li>
                    <li translate> 
                        ACHIEVEDDESC5
                    </li>
                    <li translate>
                        ACHIEVEDDESC6
                    </li>
                </ul>
            </div>
        </div>
    </div>-->

    <div class="find-more d-flex flex-column">
        <p class="header-text" >
            {{ 'CANOWNED' | translate }}<br>
           
        </p>
        <div class="outer-body">
            <h1 class="more-head" translate>
                WHYUS
            </h1>
            <div class="separator mb-3">
    
            </div>
            <!--
            <p class="more-text" translate>
                WHYUSDESC1
            </p>
            <p class="more-text" translate>
                WHYUSDESC2
            </p>-->
            <p class="more-text mb-3" translate>
                ABOUTUS-WHY-US
            </p>
            <div class="more-body">
                <div class="row d-flex justify-content-center">
                      <div class="card ml-1">
                        <div class="card-body">
                          <img class="card-image mb-3" src="./../../../../assets/images/files/about/about_pic12.jpg" alt="">
                          <h5 class="card-title mb-3" translate>WHYUSH1</h5>
                          <p class="card-text" translate>WHYUSP11</p>
                          
                        </div>
                    </div>
                        <div class="card ml-1">
                          <div class="card-body">
                            <img class="card-image mb-3" src="./../../../../assets/images/files/about/about_pic13.jpg" alt="">
                            <h5 class="card-title mb-3" translate>WHYUSH2</h5>
                            <p class="card-text" translate>WHYUSP21</p>
                            
                          </div>
                        </div>
                        <div class="card ml-1">
                          <div class="card-body">
                            <img class="card-image mb-3" src="./../../../../assets/images/files/about/about_pic14.jpeg" alt="">
                            <h5 class="card-title mb-3" translate>WHYUSH3</h5>
                            <p class="card-text" translate>WHYUSP31</p>
                            
                          </div>
                        </div>
                        <div class="card ml-1">
                          <div class="card-body">
                            <img class="card-image-1 mb-3" src="./../../../../assets/images/files/about/liveYounger0928.png" alt="">
                            <h5 class="card-title mb-3" translate>WHYUSH4</h5>
                            <p class="card-text-1" translate>WHYUSP41</p>
                            
                          </div>
                        </div>
                        <div class="card ml-1">
                            <div class="card-body">
                                <img class="card-image-1 mb-3" src="./../../../../assets/images/files/about/liveLonger09281.png" alt="">
                                <h5 class="card-title mb-3" translate>WHYUSH5</h5>
                                <p class="card-text-1" translate>WHYUSP51</p>
                                
                            </div>
                        </div>
                </div>
            </div>
        </div>
        <p class="footer-text" >
            {{ 'CANOWNDEDDESC' | translate }}
        </p>
    </div>


</div>



<!-- 
    <div class="page-banner" >
        <div>
            <h1 translate>ABOUTUS</h1>
        </div>
    </div>

    <div class="about-container">
        <div class="row">
            <div class="col-lg-2">
                <div class="side-nav">
                    <h2 translate>ABOUT</h2>
                    <a (click)="openPage('water-truth')" translate>TWTRUTH</a>
                    <a (click)="openPage('about')" translate>ABOUTUS</a>
                    <a (click)="openPage('water-analysis')" translate>WNALYSIS</a>
                </div>
            </div>

            <div class="col-lg-8">
                <div class="main">
                    <h1 translate class="p-5">AUWLTEFFORT</h1>
        
                    <div class="text-center p-5">
                        <h2 translate>AUOMISSION</h2>
                        <h2 translate>AUOMISSIONDESC1</h2>
                        <h2 translate>AUOMISSIONDESC2</h2>
                    </div>
                    <hr>
        
                    <div class="quote p-5">
                        <span class="big-quote">“</span>
                        <h3 translate>AUWILQUOTE</h3>
                        <h3 translate>AUWILQUOTESRC</h3>
                    </div>
                    <hr>
        
                    <div class="p-5">
                        <h2 translate>AUWWACHIEVED</h2>
                        <ul>
                            <li translate>AUWWACHIEVEDLIST1</li>
                            <li translate>AUWWACHIEVEDLIST2</li>
                            <li translate>AUWWACHIEVEDLIST3</li>
                            <li translate>AUWWACHIEVEDLIST4</li>
                            <li translate>AUWWACHIEVEDLIST5</li>
                            <li translate>AUWWACHIEVEDLIST6</li>
                        </ul>
                    </div>
        
                    <div class="pl-5 pr-5">
                        <img width="100%" src="./../../../../assets/images/files/about/about_pict2.jpeg" alt="">
                    </div>
        
                    <p translate class="pl-5 pr-5">AUWWACHIEVEDDESC</p>
        
                    <div class="p-5">
                        <h2 translate>AUWHYUS</h2>
                        <p translate>AUWHYUSDESC1</p>
                        <p translate>AUWHYUSDESC2</p>
                        <p translate>AUWHYUSDESC3</p>
                    </div>
        
                    <div class="p-5">
                        <h2 translate class="pb-2">AUBECAREDFOR</h2>
                        <p translate class="pb-2">AUBECAREDFORDESC</p>
        
                        <h2 translate class="pb-2">AUBEHEALTHY</h2>
                        <p translate class="pb-2">AUBEHEALTHYDESC</p>
        
                        <h2 translate class="pb-2">AUOPOP</h2>
                        <p translate class="pb-2">AUOPOPDESC</p>
        
                        <h2 translate class="pb-2">AUSAVEENV</h2>
                        <p translate class="pb-2">AUSAVEENVDESC</p>
        
                        <h2 translate class="pb-2">AUSAVEMONEY</h2>
                        <p translate class="pb-2">AUSAVEMONEYDESC</p>

                        
                        <div class="row">
                            <div class="col-sm-6 text-center">
                                <img width="100%" src="./../../../../assets/images/files/about/about_pict3.jpeg" alt="">
                            </div>
                            <div class="col-sm-6 text-center">
                                <div class="centered-container">
                                    <div class="centered-text">
                                        <p class="align-middle" translate>AUCOAO</p>
                                        <p class="align-middle" translate>AUAPADIC</p>
                                    </div>
                                </div>
                                
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-2">
    
            </div>
        </div>
    </div> -->
